import React, { useEffect } from "react"
import { RadioButtonGroup, Input } from "@opensea/ui-kit"
import { useForm, Controller } from "react-hook-form"
import styled from "styled-components"
import { Link } from "@/components/common/Link"
import { MediaInputValue } from "@/components/forms/MediaInput"
import { CREATOR_EXCLUDED_CHAINS } from "@/constants"
import { IS_TESTNET } from "@/constants/testnet"
import { Form } from "@/design-system/Form"
import { Modal } from "@/design-system/Modal"
import { Select } from "@/design-system/Select"
import { TipText } from "@/features/collections/components/CollectionCreateOrUpdatePage/CollectionFormWrapper.react"
import { GraphicUpload } from "@/features/collections/components/CollectionCreateOrUpdatePage/components/CollectionGraphicsForm"
import { ChainIdentifier } from "@/hooks/useChains/types"
import { useChainOptions } from "@/hooks/useChains/useChainOptions"
import { useIs1155ContractDeployEnabled } from "@/hooks/useFlag"
import { useTranslate } from "@/hooks/useTranslate"
import { trackClickDeployContractCollection } from "@/lib/analytics/events/collectionEvents"
import { DropDeployContractStandard } from "@/lib/graphql/__generated__/DeployContractActionModalQuery.graphql"

export type ConfigurationFormData = {
  name: string
  contract: {
    standard: DropDeployContractStandard
    isProxy: boolean
  }
  symbol: string
  chain: string
  image: MediaInputValue
}

type ContractConfigurationFormProps = Partial<ConfigurationFormData> & {
  onSubmit: (data: ConfigurationFormData) => unknown
}
type ContractType = "ERC721_STANDARD" | "ERC721_PROXY" | "ERC1155_STANDARD"

type Contract = {
  standard: DropDeployContractStandard
  isProxy: boolean
}

const CONTRACTS: { [key in ContractType]: Contract } = {
  ["ERC721_STANDARD"]: {
    standard: "ERC721",
    isProxy: false,
  },
  ["ERC721_PROXY"]: {
    standard: "ERC721",
    isProxy: true,
  },
  ["ERC1155_STANDARD"]: {
    standard: "ERC1155",
    isProxy: false,
  },
}

export const ContractConfigurationForm = ({
  onSubmit,
  ...defaultValues
}: ContractConfigurationFormProps) => {
  const t = useTranslate("collection")
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ConfigurationFormData>({
    mode: "onChange",
    defaultValues: {
      contract: CONTRACTS["ERC721_PROXY"],
      ...defaultValues,
    },
  })
  const chainOptions = useChainOptions({
    useChainIdentifier: true,
    useChainFilter: false,
  }).filter(chainOption => !CREATOR_EXCLUDED_CHAINS.includes(chainOption.value))
  const selectedChain = watch("chain")
  const contract = watch("contract")
  const symbol = watch("symbol")
  useEffect(() => {
    if (selectedChain) {
      return
    }
    const defaultChain: ChainIdentifier = IS_TESTNET ? "SEPOLIA" : "ETHEREUM"
    const chain = chainOptions.find(c => c.value === defaultChain)
    if (chain) {
      setValue("chain", chain.value)
    }
  }, [chainOptions, setValue, selectedChain])

  const submit = handleSubmit(onSubmit)

  const contractTypeOptions = [
    {
      body: t(
        "deployContractForm.contractType.proxy.body",
        "Cheaper to deploy but mint, transfer, and sale interactions will require extra gas fees.",
      ),
      label: t("deployContractForm.contractType.proxy.label", "Proxy contract"),
      value: "ERC721_PROXY" as ContractType,
    },
    {
      body: t(
        "deployContractForm.contractType.standard.body",
        "More expensive to deploy but mint, transfer, and sale interactions will require less gas fees.",
      ),
      label: t(
        "deployContractForm.contractType.standard.label",
        "Standard contract",
      ),
      value: "ERC721_STANDARD" as ContractType,
    },
  ]
  const has1155DeployAccess = useIs1155ContractDeployEnabled()
  if (has1155DeployAccess) {
    contractTypeOptions.push({
      body: t(
        "deployContractForm.contractType.1155.body",
        "Mint multiple of the same token",
      ),
      label: t("deployContractForm.contractType.1155.label", "1155 contract"),
      value: "ERC1155_STANDARD" as ContractType,
    })
  }

  return (
    <Modal.Form
      data-testid="contract-configure-form-container"
      onSubmit={submit}
    >
      <Modal.Header>
        <Modal.Header.Title>
          {t("deployContractForm.contractType.title", "Create a collection")}
        </Modal.Header.Title>
        <Modal.Header.Description>
          {t(
            "deployContractForm.contractType.description",
            "Deploying your own contract requires uploading your metadata outside of OpenSea.",
          )}
        </Modal.Header.Description>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          htmlFor="image"
          label={t("forms.collectionForm.logoImage.label", "Logo image")}
        >
          <Controller
            control={control}
            name="image"
            render={({ field, ...props }) => {
              return (
                <GraphicUpload
                  aria-label={t(
                    "forms.collectionForm.logoImageInput.label",
                    "Select a logo image",
                  )}
                  aspectRatio={1}
                  guidance={t(
                    "deployContractForm.logo.helpText",
                    "350 x 350 px recommended",
                  )}
                  id={field.name}
                  invalid={props.fieldState.invalid}
                  name={field.name}
                  value={[field.value]}
                  onChange={files => field.onChange(files[0])}
                />
              )
            }}
            rules={{ required: true }}
          />
        </Form.Control>
        <Form.Control
          error={errors.name?.message}
          label={t("deployContractForm.contractName.label", "Name")}
        >
          <Input
            aria-label={t("deployContractForm.ariaContraName", "Contract name")}
            {...register("name", { required: true })}
            error={!!errors.name}
            placeholder={t(
              "deployContractForm.name.example",
              "My Collection Name",
            )}
          />
        </Form.Control>
        <Form.Control
          error={errors.symbol?.message}
          label={t("deployContractForm.tokenSymbolField.label", "Token symbol")}
          tip={
            <TipText>
              {t(
                "deployContractForm.tokenSymbolField.tip",
                "The token symbol is shown on the block explorer when others view your smart contract.",
              )}
            </TipText>
          }
        >
          <Input
            aria-label={t("deployContractForm.ariaTokenSymbol", "Token symbol")}
            error={!!errors.symbol}
            placeholder={t("deployContractForm.symbol.example", "MCN")}
            {...register("symbol", { required: true })}
          />
        </Form.Control>
        <Form.Control label={t("deployContractForm.chain", "Blockchain")}>
          <Controller
            control={control}
            name="chain"
            render={({ field }) => (
              <SelectChain
                autoComplete="off"
                clearable={false}
                id={field.name}
                options={chainOptions}
                overrides={{
                  ContentItem: {
                    props: {
                      "aria-label": "Chain selector",
                    },
                  },
                }}
                placeholder={t(
                  "deployContractForm.selectChain",
                  "Select a chain",
                )}
                value={field.value}
                variant="item"
                onSelect={option => option && field.onChange(option.value)}
              />
            )}
          />
        </Form.Control>
        <Form.Control
          error={errors.contract?.message}
          label={t(
            "deployContractForm.contractType.proxy.input.label",
            "Contract type",
          )}
          tip={
            <TipText>
              {t(
                "deployContractForm.contractType.proxy.input.tip",
                "{{learn_more}} about each contract type.",
                {
                  learn_more: (
                    <Link href="https://support.opensea.io/hc/en-us/articles/13591858470803-Part-1-Deploy-a-smart-contract">
                      {t(
                        "deployContractForm.contractType.proxy.input.learnMoreLink",
                        "Learn more",
                      )}
                    </Link>
                  ),
                },
              )}
            </TipText>
          }
        >
          <Controller
            control={control}
            name="contract"
            render={({ field }) => (
              <RadioButtonGroup
                onValueChange={value => {
                  return (
                    value && field.onChange(CONTRACTS[value as ContractType])
                  )
                }}
              >
                {contractTypeOptions.map(option => (
                  <RadioButtonGroup.Item
                    key={option.value}
                    value={option.value}
                  >
                    <RadioButtonGroup.Item.Content>
                      <RadioButtonGroup.Item.Title>
                        {option.label}
                      </RadioButtonGroup.Item.Title>
                      <RadioButtonGroup.Item.Description>
                        {option.body}
                      </RadioButtonGroup.Item.Description>
                    </RadioButtonGroup.Item.Content>
                  </RadioButtonGroup.Item>
                ))}
              </RadioButtonGroup>
            )}
          />
        </Form.Control>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button
          type="submit"
          onClick={() =>
            trackClickDeployContractCollection({
              chain: selectedChain,
              isProxy: contract.isProxy,
              symbol,
            })
          }
        >
          {t("deployContractForm.deployContract", "Deploy contract")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal.Form>
  )
}

const SelectChain = styled(Select)`
  span {
    font-weight: 600;
    font-size: 16px;
  }
`
