import React, { useState } from "react"
import { Text, AspectRatio, classNames, Flex } from "@opensea/ui-kit"
import dynamic from "next/dynamic"
import { DropzoneOptions } from "react-dropzone"
import styled, { css } from "styled-components"
import { MuxVideo } from "@/components/common/MuxVideo"
import type { ModelSceneProps } from "@/components/viz/ModelScene.react"
import { Block } from "@/design-system/Block"
import { FileInput, FileInputProps } from "@/design-system/FileInput"
import { Image } from "@/design-system/Image"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { getBytesFromMegabytes } from "@/lib/helpers/file"
import { MediaType, mediaTypeFromFile } from "@/lib/helpers/media"
import { UnreachableCaseError } from "@/lib/helpers/type"
import { MAX_MEDIA_SIZE_MB } from "../../../constants"
import { ImageInputValue } from "../ImageInput"

const FOOTER_DROPZONE_HEIGHT = "50px"
const MAX_SIZE = getBytesFromMegabytes(MAX_MEDIA_SIZE_MB)

export type MediaInputValue = ImageInputValue & {
  type: MediaType
}

const DynamicModelScene = dynamic<ModelSceneProps>(
  () => import("@/components/viz/ModelScene.react").then(mod => mod.ModelScene),
  { ssr: false },
)

type MediaInputFileInputProps = Pick<
  FileInputProps,
  | "accept"
  | "id"
  | "name"
  | "disabled"
  | "height"
  | "width"
  | "maxWidth"
  | "maxHeight"
  | "maxSize"
  | "overlay"
  | "previewOverlay"
  | "disableInputOverlay"
  | "closeIcon"
  | "variant"
  | "className"
  | "placeholderVariant"
  | "multiple"
  | "maxFiles"
  | "extra"
>

export type MediaInputProps = {
  enableInteractiveContent?: boolean
  supportedExtensions?: string[]
  value?: MediaInputValue[]
  onChange: (values: MediaInputValue[]) => unknown
} & MediaInputFileInputProps

const DEFAULT_SUPPORTED_EXTENSIONS = [
  ".jpg",
  ".jpeg",
  ".png",
  ".gif",
  ".svg",
  ".mp4",
  ".webm",
  ".mp3",
  ".wav",
  ".ogg",
  ".glb",
  ".gltf",
]

export const MediaInput = ({
  onChange,
  value,
  height,
  width,
  maxHeight,
  maxWidth,
  maxSize = MAX_SIZE,
  supportedExtensions = DEFAULT_SUPPORTED_EXTENSIONS,
  enableInteractiveContent = true,
  multiple,
  maxFiles = 1,
  ...rest
}: MediaInputProps) => {
  const t = useTranslate("components")

  // We keep this local state so we are able to revoke object urls to prevent memory leaks
  const [previewUrls, setPreviewUrls] = useState<string[]>([])

  // revoke object url for current image when component unmounts
  useMountEffect(() => {
    return () => {
      previewUrls.forEach(url => URL.revokeObjectURL(url))
    }
  })

  const onFilesChange = (files: File[]) => {
    const slicedFiles = files.slice(0, maxFiles)
    // revoke object url for previous images
    previewUrls.forEach(url => URL.revokeObjectURL(url))

    const mediaInputValues = slicedFiles.map(file => {
      const type = mediaTypeFromFile(file)

      if (!type) {
        throw new Error(
          t(
            "forms.mediaInput.mediaTypeError",
            "Failed to deduce media type for file: {{name}} type: {{type}}",
            { name: file.name, type: file.type },
            { forceString: true },
          ),
        )
      }

      const url = URL.createObjectURL(file)

      return { file, type, url }
    })

    setPreviewUrls(mediaInputValues.map(({ url }) => url))
    onChange(mediaInputValues)
  }

  const validator: DropzoneOptions["validator"] = (
    file: File | DataTransferItem,
  ) => {
    if (file instanceof File) {
      const pathSplit = file.name.split(".")
      const extension = pathSplit[pathSplit.length - 1].toLowerCase()
      if (!supportedExtensions.includes(`.${extension}`)) {
        return {
          code: "file-invalid-type",
          message: t(
            "forms.mediaInput.invalidFileType",
            "Invalid file type detected",
          ),
        }
      }
    }
    return null
  }

  const numFiles = value?.length ?? 0

  if (numFiles > 1 || (maxFiles > 1 && numFiles === 1)) {
    const handleMultipleFilesChanged = (files: File[], inputIndex: number) => {
      const currentFiles = (value ?? [])
        .map(({ file }) => file)
        .flatMap(file => (file ? [file] : []))

      // If files is empty, we are simply removing the file at that index
      if (files.length === 0) {
        onFilesChange(currentFiles.filter((_, index) => index !== inputIndex))
      }

      // If files is not empty, directly add in the new files startinf from the specified inputIndex
      else {
        const newFiles = [
          ...currentFiles.slice(0, inputIndex),
          ...files,
          ...currentFiles.slice(inputIndex + files.length),
        ]
        onFilesChange(newFiles)
      }
    }
    return (
      <GridContainer>
        {value?.map((mediaInputValue, index) => (
          <AspectRatio key={index} ratio={1 / 1}>
            <IndividualMediaInput
              enableInteractiveContent={enableInteractiveContent}
              height="100%"
              maxFiles={maxFiles}
              maxSize={maxSize}
              multiple={multiple}
              validator={validator}
              value={mediaInputValue}
              onChange={files => handleMultipleFilesChanged(files, index)}
              {...rest}
            />
          </AspectRatio>
        ))}
        <IndividualMediaInput
          enableInteractiveContent={enableInteractiveContent}
          height="100%"
          maxFiles={maxFiles}
          maxHeight={maxHeight}
          multiple={multiple}
          validator={validator}
          onChange={files => handleMultipleFilesChanged(files, numFiles)}
          {...rest}
          disabled={numFiles >= maxFiles}
          placeholderVariant="add"
        />
      </GridContainer>
    )
  }

  return (
    <IndividualMediaInput
      enableInteractiveContent={enableInteractiveContent}
      height={height}
      maxFiles={maxFiles}
      maxHeight={maxHeight}
      maxSize={maxSize}
      maxWidth={maxWidth}
      multiple={multiple}
      validator={validator}
      value={value?.[0]}
      width={width}
      onChange={onFilesChange}
      {...rest}
    />
  )
}

type IndividualMediaInputProps = {
  value?: MediaInputValue
} & Pick<MediaInputProps, "enableInteractiveContent"> &
  MediaInputFileInputProps &
  Pick<FileInputProps, "validator" | "onChange" | "preview" | "removable">

const IndividualMediaInput = ({
  value,
  enableInteractiveContent = true,
  maxHeight,
  height,
  maxFiles,
  maxSize,
  maxWidth,
  multiple,
  validator,
  width,
  onChange,
  preview,
  ...rest
}: IndividualMediaInputProps) => {
  const t = useTranslate("components")

  const renderWebGl = (value: MediaInputValue) => {
    return (
      <Block
        style={{
          width: "100%",
          height: `calc(${
            maxHeight ?? height ?? "100%"
          } - ${FOOTER_DROPZONE_HEIGHT})`,
        }}
      >
        <DynamicModelScene backgroundColor="white" url={value.url} />
      </Block>
    )
  }

  const renderVideo = (value: MediaInputValue) => {
    return (
      <video
        autoPlay={enableInteractiveContent}
        controls={enableInteractiveContent}
        controlsList="nodownload"
        loop
        muted
        preload="auto"
        src={value.url}
        style={{
          width: "100%",
          objectFit: enableInteractiveContent ? "contain" : "cover",
          height: enableInteractiveContent
            ? `calc(100% - ${FOOTER_DROPZONE_HEIGHT})`
            : "100%",
        }}
      />
    )
  }

  const renderAudio = (value: MediaInputValue) => {
    return (
      /* eslint-disable-next-line jsx-a11y/media-has-caption */
      <audio
        autoPlay={false}
        controls={enableInteractiveContent}
        controlsList="nodownload"
        loop
        preload="auto"
        src={value.url}
        style={{ width: "100%" }}
      />
    )
  }

  const renderImage = (value: MediaInputValue) => {
    return (
      <Image
        alt={`${value.url} preview image`}
        layout="fill"
        objectFit="cover"
        src={value.url}
      />
    )
  }

  const renderMuxVideo = (value: MediaInputValue) => {
    return (
      <StyledMuxVideoContainer
        $enableInteractiveContent={enableInteractiveContent}
        autoPlay={enableInteractiveContent}
        muted
        playbackId={value.url}
        title={value.file?.name ?? ""}
      />
    )
  }

  const renderContent = (value: MediaInputValue) => {
    switch (value.type) {
      case "audio":
        return renderAudio(value)
      case "image":
        return renderImage(value)
      case "video":
        return renderVideo(value)
      case "webgl":
        return renderWebGl(value)
      case "mux_video":
        return renderMuxVideo(value)
      default:
        throw new UnreachableCaseError(value.type)
    }
  }

  return !value || value.type === "image" || !enableInteractiveContent ? (
    <FileInput
      {...rest}
      height={!value ? maxHeight ?? height : height}
      maxFiles={maxFiles}
      maxHeight={maxHeight}
      maxSize={maxSize}
      maxWidth={maxWidth}
      multiple={multiple}
      preview={preview ?? (value && renderContent(value))}
      validator={validator}
      width={width}
      onChange={onChange}
    />
  ) : (
    <Flex
      className={classNames("flex-col rounded-default")}
      style={{
        height: value.type === "audio" ? "fit-content" : height,
        maxHeight,
        maxWidth,
        width,
      }}
    >
      {renderContent(value)}
      <Block className="relative" height={FOOTER_DROPZONE_HEIGHT}>
        <FileInput
          maxFiles={maxFiles}
          maxSize={maxSize}
          multiple={multiple}
          validator={validator}
          variant="overlay"
          onChange={onChange}
          {...rest}
        >
          <Text asChild color="secondary" size="medium" weight="semibold">
            <p>{t("forms.mediaInput.cta", "Change")}</p>
          </Text>
        </FileInput>
      </Block>
    </Flex>
  )
}

const StyledMuxVideoContainer = styled(MuxVideo)<{
  $enableInteractiveContent: boolean
}>`
  width: 100%;
  height: 0;
  /* 12:5 aspect ratio */
  padding-bottom: 41.667%;

  ${props =>
    !props.$enableInteractiveContent &&
    css`
      mux-player {
        --controls: none;
      }
    `}
`

const GridContainer = styled(Block)`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: 1fr;
`
