/**
 * @generated SignedSource<<1d8eee2de66b72e01d069eb560a689d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CollectionFormProvider_data$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useCollectionForm_data">;
  readonly " $fragmentType": "CollectionFormProvider_data";
};
export type CollectionFormProvider_data$key = {
  readonly " $data"?: CollectionFormProvider_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"CollectionFormProvider_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CollectionFormProvider_data",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useCollectionForm_data"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "33b086fc3ac0ca890feff6fc089dff2f";

export default node;
