/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import {
  Icon,
  Container,
  classNames,
  VerticalAligned,
  FlexColumn,
  Text,
  Flex,
} from "@opensea/ui-kit"
import clsx from "clsx"
import styled, { css } from "styled-components"
import { Link } from "@/components/common/Link"
import { OpenSeaPage } from "@/containers/OpenSeaPage.react"
import { truncateText } from "@/lib/helpers/stringUtils"
import { media } from "@/styles/styleUtils"
import { $nav_height } from "@/styles/variables"

type Props = {
  // TODO: Can these breadcrumbs be generated via routing instead of using props? - Roy
  breadcrumbLinks?: Array<{
    href?: string
    label: string
  }>
  children?: React.ReactNode
  containerClassName?: string
  className?: string
  fullWidth?: boolean
  title?: string
  subtitle?: React.ReactNode
}

export const CollectionManager = ({
  breadcrumbLinks,
  children,
  className,
  containerClassName,
  fullWidth,
  title,
  subtitle,
}: Props) => (
  <OpenSeaPage hideFooter>
    <DivContainer>
      <section className={clsx("CollectionManager--main", className)}>
        {breadcrumbLinks && (
          <div className="CollectionManager--topbar">
            {breadcrumbLinks.map(breadcrumb => (
              <Flex key={breadcrumb.label}>
                {breadcrumb.href ? (
                  <Link
                    className="CollectionManager--breadcrumb-link"
                    href={breadcrumb.href}
                  >
                    <span>{truncateText(breadcrumb.label, 15)}</span>
                  </Link>
                ) : (
                  <span className="CollectionManager--breadcrumb-current">
                    {truncateText(breadcrumb.label, 15)}
                  </span>
                )}

                {(title || breadcrumb.href) && (
                  <VerticalAligned>
                    <Icon
                      className="text-secondary"
                      size={12}
                      value="chevron_right"
                    />
                  </VerticalAligned>
                )}
              </Flex>
            ))}
            {title && (
              <span className="CollectionManager--breadcrumb-current">
                {truncateText(title, 15)}
              </span>
            )}
          </div>
        )}
        <Container
          className={classNames(
            "CollectionManager",
            "p-6",
            { container: true, "narrow-container": !fullWidth },
            containerClassName,
          )}
        >
          {(title || subtitle) && (
            <FlexColumn asChild>
              <header>
                {title && (
                  <Text.Display asChild className="mb-4" size="large">
                    <h1>{title}</h1>
                  </Text.Display>
                )}
                {subtitle ? <Text className="mb-4">{subtitle}</Text> : null}
              </header>
            </FlexColumn>
          )}

          {children}
        </Container>
      </section>
    </DivContainer>
  </OpenSeaPage>
)

const DivContainer = styled.div`
  .CollectionManager--sidebar-item {
    align-items: center;
    display: flex;
    justify-content: center;
    color: ${props => props.theme.colors.text.primary};
    padding: 12px 16px;

    &:hover {
      background-color: ${props =>
        props.theme.colors.components.background.gray1};
      color: ${props => props.theme.colors.text.primary};
      font-weight: 500;
    }

    .CollectionManager--sidebar-item-text {
      display: none;
    }

    ${media({
      md: css`
        justify-content: initial;

        .CollectionManager--sidebar-item-text {
          display: initial;
          margin-left: 8px;
        }
      `,
    })}
  }

  .CollectionManager--main {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .CollectionManager--topbar {
    align-items: center;
    background-color: ${props => props.theme.colors.base1};
    border-bottom: 1px solid
      ${props => props.theme.colors.components.border.level2};
    display: flex;
    align-items: center;
    height: ${$nav_height};
    padding: 0 8px;
    width: 100%;
    z-index: 4;

    .CollectionManager--breadcrumb-link {
      margin: 0 8px;
      color: ${props => props.theme.colors.gray};
      font-size: 11px;
      &:hover {
        text-decoration: underline;
      }
    }

    .CollectionManager--breadcrumb-current {
      margin: 0 8px;
      color: ${props => props.theme.colors.text.primary};
      font-size: 11px;
    }

    ${media({
      md: css`
        .CollectionManager--breadcrumb-link,
        .CollectionManager--breadcrumb-current {
          font-size: 14px;
        }
      `,
    })}
  }

  .CollectionManager--container {
    &.CollectionManager--container-with-topbar {
      padding: ${$nav_height} 16px;

      &.container {
        padding-left: 0;
        padding-right: 0;
      }
    }

    &.CollectionManager--narrow-container {
      width: 646px;
      max-width: 100%;
    }

    width: 1280px;
    max-width: 100%;
  }

  .CollectionManager--title-badge {
    color: ${props => props.theme.colors.seaHorse};
    position: relative;
    top: -10px;
    font-size: 16px;
    left: 8px;
  }
`
