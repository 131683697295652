import React, { useContext } from "react"
import { inputVariants } from "@opensea/ui-kit"
import { Controller } from "react-hook-form"
import styled from "styled-components"
import { MediaInputProps } from "@/components/forms/MediaInput"
import { Block } from "@/design-system/Block"
import { FormControl } from "@/design-system/FormControl"
import { MediaUpload } from "@/features/primary-drops/components/DropPageEditor/components/MediaUpload"
import { ResponsiveText } from "@/features/primary-drops/components/ResponsiveText"
import { useTranslate } from "@/hooks/useTranslate"
import {
  CollectionFormWrapper,
  TipText,
} from "../../CollectionFormWrapper.react"
import { FormCollectionContext } from "../../containers/CollectionFormProvider.react"

export const CollectionGraphicsForm = () => {
  const t = useTranslate("collectionEdit")
  const {
    form: { control },
  } = useContext(FormCollectionContext)

  const renderImage = () => (
    <FormControl
      htmlFor="image"
      label={t("forms.collectionForm.logoImage.label", "Logo image")}
      tip={
        <TipText>
          {t(
            "forms.collectionForm.logoImage.tip",
            "This image will also be used for display purposes.",
          )}
        </TipText>
      }
    >
      <Controller
        control={control}
        name="image"
        render={({ field, ...props }) => {
          return (
            <GraphicUpload
              aria-label={t(
                "forms.collectionForm.logoImageInput.label",
                "Select a logo image",
              )}
              aspectRatio={1}
              guidance="Recommended size: 350 x 350"
              id={field.name}
              invalid={props.fieldState.invalid}
              name={field.name}
              value={field.value ? [field.value] : undefined}
              onChange={files => {
                field.onChange(files[0] ?? null)
              }}
            />
          )
        }}
      />
    </FormControl>
  )

  const renderFeaturedImage = () => (
    <FormControl
      htmlFor="featuredImage"
      label={t("forms.collectionForm.featuredImage.label", "Featured image")}
      tip={
        <TipText>
          {t(
            "forms.collectionForm.featuredImage.tip",
            "This image will be used for featuring your collection on the homepage, category pages, or other display areas of OpenSea.",
          )}
        </TipText>
      }
    >
      <Controller
        control={control}
        name="featuredImage"
        render={({ field }) => (
          <GraphicUpload
            aria-label={t(
              "forms.collectionForm.featuredImageInput.label",
              "Select a featured image",
            )}
            aspectRatio={1.5}
            guidance="Recommended size: 600 x 400"
            id={field.name}
            name={field.name}
            value={field.value ? [field.value] : undefined}
            onChange={files => field.onChange(files[0] ?? null)}
          />
        )}
      />
    </FormControl>
  )

  const renderBannerImage = () => (
    <FormControl
      htmlFor="bannerImage"
      label={t("forms.collectionForm.bannerImage.label", "Banner image")}
      tip={
        <TipText>
          {t(
            "forms.collectionForm.bannerImage.tip",
            "This image will appear at the top of your collection page.",
          )}
        </TipText>
      }
    >
      <Controller
        control={control}
        name="bannerImage"
        render={({ field }) => (
          <GraphicUpload
            aria-label={t(
              "forms.collectionForm.bannerImageInput.label",
              "Select a banner image",
            )}
            aspectRatio={4}
            guidance="Recommended size: 1400 x 350"
            id={field.name}
            name={field.name}
            value={field.value ? [field.value] : undefined}
            onChange={files => field.onChange(files[0])}
          />
        )}
      />
    </FormControl>
  )

  return (
    <CollectionFormWrapper title="Collection graphics">
      {renderImage()}
      {renderFeaturedImage()}
      {renderBannerImage()}
    </CollectionFormWrapper>
  )
}

interface GraphicUploadInputProps extends MediaInputProps {
  aspectRatio: number
  guidance: string
  invalid?: boolean
}

export const GraphicUpload = ({
  guidance,
  aspectRatio,
  invalid,
  ...props
}: GraphicUploadInputProps) => {
  return (
    <GraphicUploadContainer $invalid={!!invalid} className={inputVariants()}>
      {!!props.value?.length && (
        <MediaUpload.RemoveButton onRemove={() => props.onChange([])} />
      )}
      <GraphicUploadInnerContainer aspectRatio={aspectRatio}>
        <MediaUpload.Input {...props} removable={false} shape="fill" />
      </GraphicUploadInnerContainer>
      <ResponsiveText.Body className="mt-4 text-secondary" size="small">
        {guidance}
      </ResponsiveText.Body>
    </GraphicUploadContainer>
  )
}

const GraphicUploadContainer = styled(Block)<{ $invalid: boolean }>`
  position: relative;
  border-radius: ${props => props.theme.borderRadius.default};
  border: 1px solid
    ${props =>
      props.$invalid
        ? props.theme.colors.error
        : props.theme.colors.components.border.level2};
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 12px 24px;
`

const GraphicUploadInnerContainer = styled(Block)<{ aspectRatio: number }>`
  position: relative;
  height: 96px;
  width: calc(96px * ${props => props.aspectRatio});
  max-width: 100%;
`
