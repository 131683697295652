/**
 * @generated SignedSource<<280d91786ddd54197010020bbe1fc8a5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IdentityInputType = {
  address?: string | null;
  name?: string | null;
  username?: string | null;
};
export type MyCollectionsPageRefetchQuery$variables = {
  count?: number | null;
  cursor?: string | null;
  identity: IdentityInputType;
};
export type MyCollectionsPageRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MyCollectionsPage_collections">;
};
export type MyCollectionsPageRefetchQuery = {
  response: MyCollectionsPageRefetchQuery$data;
  variables: MyCollectionsPageRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "identity"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "editor",
    "variableName": "identity"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": "CREATED_DATE"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MyCollectionsPageRefetchQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "identity",
            "variableName": "identity"
          }
        ],
        "kind": "FragmentSpread",
        "name": "MyCollectionsPage_collections"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MyCollectionsPageRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionTypeConnection",
        "kind": "LinkedField",
        "name": "collections",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionTypeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CollectionType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "relayId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "banner",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "verificationStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCategory",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "editor",
          "sortBy"
        ],
        "handle": "connection",
        "key": "MyCollectionsPage_collections",
        "kind": "LinkedHandle",
        "name": "collections"
      }
    ]
  },
  "params": {
    "cacheID": "d95a196b79719803ddbcf78ed003af95",
    "id": null,
    "metadata": {},
    "name": "MyCollectionsPageRefetchQuery",
    "operationKind": "query",
    "text": "query MyCollectionsPageRefetchQuery(\n  $count: Int\n  $cursor: String\n  $identity: IdentityInputType!\n) {\n  ...MyCollectionsPage_collections_3StDC7\n}\n\nfragment CollectionCardContextMenu_data on CollectionType {\n  ...collection_url\n  ...CollectionManagerDeleteModal_data\n}\n\nfragment CollectionManagerDeleteModal_data on CollectionType {\n  relayId\n}\n\nfragment GlobalCollectionCard_data_3lARXt on CollectionType {\n  logo\n  banner\n  name\n  verificationStatus\n  ...collection_url\n  ...CollectionCardContextMenu_data\n}\n\nfragment MyCollectionsPage_collections_3StDC7 on Query {\n  collections(editor: $identity, first: $count, after: $cursor, sortBy: CREATED_DATE) {\n    edges {\n      node {\n        relayId\n        ...GlobalCollectionCard_data_3lARXt\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment collection_url on CollectionType {\n  slug\n  isCategory\n}\n"
  }
};
})();

(node as any).hash = "3d8bbec0edc507406489fb9d31f53cc0";

export default node;
