import React from "react"
import { HUES } from "../../styles/themes"

type Props = {
  className?: string
  width?: number
  height?: number
  fill?: string
}

export const PencilAndRulerIcon = ({
  className,
  fill = HUES.gray,
  height,
  width,
}: Props) => (
  <svg
    className={className}
    fill={fill}
    style={{ height, width }}
    viewBox="0 0 24 24"
  >
    <path d="M9.14062 3.1875L6.51562 5.8125L6 6.32812L4.92188 5.25L5.4375 4.73438L8.0625 2.10938L6 0L0 6L4.92188 10.9688L10.9219 4.96875L9.14062 3.1875ZM18 24L24 18L21.8906 15.9375L19.2656 18.5625L18.75 19.0781L17.6719 18L18.1875 17.4844L20.8125 14.8594L19.0312 13.0781L13.0312 19.0781L18 24ZM1.5 16.5L0 24L7.5 22.5L19.6875 10.3125L13.6875 4.3125L1.5 16.5ZM24 6L18 0L14.7656 3.23438L20.7656 9.23438L24 6Z" />
  </svg>
)
