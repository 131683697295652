/* eslint-disable tailwindcss/no-custom-classname */
import React from "react"
import styled, { css } from "styled-components"
import { Link } from "@/components/common/Link"
import { Block } from "@/design-system/Block"
import { CreateCollectionModal } from "@/features/collections/components/CreateCollectionModal"
import { useTranslate } from "@/hooks/useTranslate"
import { media } from "@/styles/styleUtils"
import { COLLECTION_MANAGER_POST_URL } from "../../constants"

export const CollectionManagerIndexHeader = () => {
  const t = useTranslate("components")

  return (
    <InfoContainer>
      <div className="collectionManagerIndexHeader--info">
        {t(
          "collections.header.title",
          "Create, curate, and manage collections of unique NFTs to share and sell.",
        )}
        <Link href={COLLECTION_MANAGER_POST_URL}>
          {t("collections.header.learnMoreLink", "Learn more")}
        </Link>
      </div>
      <div className="collectionManagerIndexHeader--buttons">
        <Block marginRight="16px">
          <CreateCollectionModal />
        </Block>
      </div>
    </InfoContainer>
  )
}

const InfoContainer = styled.div`
  .collectionManagerIndexHeader--info {
    gap: 4px;
    margin: 18px auto 0;
    text-align: center;
    max-width: 320px;
    font-size: 16px;

    .collectionManagerIndexHeader--info-icon {
      margin-left: 4px;
    }
  }

  .collectionManagerIndexHeader--buttons {
    margin: 24px auto 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .collectionManagerIndexHeader--more-btn {
      padding: 12px;
    }
  }

  ${media({
    md: css`
      .collectionManagerIndexHeader--info {
        display: flex;
        align-items: center;
        max-width: 100%;
      }
      .collectionManagerIndexHeader--buttons {
        justify-content: flex-start;
      }
    `,
  })}
`
