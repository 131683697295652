import React from "react"
import { Spinner } from "@opensea/ui-kit"
import { times } from "lodash"
import { usePaginationFragment } from "react-relay"
import styled, { css } from "styled-components"
import { getAppInitialProps } from "@/components/app/initialProps"
import { CollectionManagerIndexHeader } from "@/components/collections/CollectionManagerIndexHeader.react"
import { GlobalCollectionCard } from "@/components/collections/GlobalCollectionCard/GlobalCollectionCard.react"
import { CollectionManager } from "@/components/layout/CollectionManager.react"
import { Grid } from "@/design-system/Grid/Grid.react"
import {
  loadNextToLoadMore,
  ScrollingPaginator,
} from "@/design-system/ScrollingPaginator"
import { pageTitle, OpenSeaHead } from "@/features/seo"
import { useMountEffect } from "@/hooks/useMountEffect"
import { useTranslate } from "@/hooks/useTranslate"
import { trackCollectionManagerPage } from "@/lib/analytics/events/pageEvents"
import Wallet from "@/lib/chain/wallet"
import type { MyCollectionsPage_collections$key } from "@/lib/graphql/__generated__/MyCollectionsPage_collections.graphql"
import type { MyCollectionsPageQuery } from "@/lib/graphql/__generated__/MyCollectionsPageQuery.graphql"
import { getNodes, graphql, GraphQLInitialProps } from "@/lib/graphql/graphql"
import type { GraphQLNextPage } from "@/lib/graphql/GraphQLPage.react"
import { media } from "@/styles/styleUtils"

const PAGE_SIZE = 12

type Props = {
  data: MyCollectionsPage_collections$key | null
}

const MyCollectionsPageView = ({ data: dataKey }: Props) => {
  const t = useTranslate("collections")
  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment(
    graphql`
      fragment MyCollectionsPage_collections on Query
      @argumentDefinitions(identity: { type: "IdentityInputType!" })
      @refetchable(queryName: "MyCollectionsPageRefetchQuery") {
        collections(
          editor: $identity
          first: $count
          after: $cursor
          sortBy: CREATED_DATE
        ) @connection(key: "MyCollectionsPage_collections") {
          __id
          edges {
            node {
              relayId
              ...GlobalCollectionCard_data
                @arguments(showContextMenu: true, showStats: false)
            }
          }
        }
      }
    `,
    dataKey,
  )

  useMountEffect(() => {
    trackCollectionManagerPage()
  })

  const connectionId = data?.collections.__id
  const renderCollections = () => {
    const collections = getNodes(data?.collections)

    return collections.length ? (
      <Grid>
        {collections.map(collection => (
          <Grid.Item key={collection.relayId} sm={6} xl={3}>
            <GlobalCollectionCard
              allowMissingLogo
              collection={collection}
              connectionId={connectionId}
              showContextMenu
            />
          </Grid.Item>
        ))}
      </Grid>
    ) : null
  }

  const renderSkeletons = () => {
    return (
      <DivContainer>
        {times(PAGE_SIZE, index => (
          <GlobalCollectionCard.Skeleton key={index} />
        ))}
      </DivContainer>
    )
  }

  const collections = data?.collections
  const subtitle = <CollectionManagerIndexHeader />

  return (
    <CollectionManager
      fullWidth
      subtitle={subtitle}
      title={t("myCollections.title", "My Collections")}
    >
      <OpenSeaHead
        title={pageTitle(t("myCollections.pageTitle", "My Collections"))}
      />

      {collections ? renderCollections() : renderSkeletons()}
      <ScrollingPaginator
        intersectionOptions={{ rootMargin: "512px" }}
        page={{
          hasMore: () => hasNext,
          isLoading: () => isLoadingNext,
          loadMore: count => loadNextToLoadMore({ loadNext, count }),
        }}
        size={PAGE_SIZE}
      >
        <LoaderContainer>
          <Spinner size="medium" />
        </LoaderContainer>
      </ScrollingPaginator>
    </CollectionManager>
  )
}
const LoaderContainer = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 16px;
`

const DivContainer = styled.div`
  ${media({
    xs: css`
      > * {
        margin-bottom: 16px;
      }
    `,
    md: css`
       {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }
      > * {
        margin-bottom: 0;
      }
    `,
    lg: css`
       {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
      }
    `,
  })}
`

export const MyCollectionsPage: GraphQLNextPage<MyCollectionsPageQuery> = ({
  data,
}) => {
  return <MyCollectionsPageView data={data} />
}

const query = graphql`
  query MyCollectionsPageQuery(
    $identity: IdentityInputType!
    $count: Int!
    $cursor: String
  ) {
    ...MyCollectionsPage_collections @arguments(identity: $identity)
  }
`
MyCollectionsPage.getInitialProps = async (
  ctx,
): Promise<GraphQLInitialProps<MyCollectionsPageQuery>> => {
  const variables = {
    count: PAGE_SIZE,
    identity: Wallet.fromContext(ctx).getActiveAccountKey() ?? {},
  }

  const appInitialProps = await getAppInitialProps(ctx, {
    query,
    variables,
    cachePolicy: { scope: "Private" },
  })

  return { ...appInitialProps, variables }
}
