import React, { useMemo } from "react"
import {
  Icon,
  UnstyledButton,
  TextBodyProps,
  Text,
  Spinner,
  CenterAligned,
  classNames,
  inputVariants,
  CenterAlignedProps,
} from "@opensea/ui-kit"
import { AnimatePresence, motion } from "framer-motion"
import { chain } from "lodash"
import styled, { css } from "styled-components"
import {
  MediaInputProps,
  MediaInput,
  SUPPORTED_IMAGE_EXTENSIONS,
  SUPPORTED_VIDEO_EXTENSIONS,
} from "@/components/forms/MediaInput"
import { Block } from "@/design-system/Block"
import { Flex } from "@/design-system/Flex"
import { interactiveStylesPrimary } from "@/design-system/utils"
import { useTranslate } from "@/hooks/useTranslate"
import { themeVariant } from "@/styles/styleUtils"

type InputShape = "square" | "circle" | "landscape" | "portrait" | "fill"

type MediaUploadInputProps = Pick<
  MediaInputProps,
  | "onChange"
  | "value"
  | "disabled"
  | "overlay"
  | "previewOverlay"
  | "className"
  | "closeIcon"
> & {
  allowVideoFiles?: boolean
  isEmbedded?: boolean
  removable?: boolean
  shape?: InputShape
}

type UploadStatus = "idle" | "uploading" | "complete"

type MediaUploadDescriptionProps = {
  disableAnimation?: boolean
  status: UploadStatus
  textOverrides?: {
    uploading?: string
    complete?: string
  }
} & Omit<TextBodyProps, "size">

// Take in a file type (image or video) and return an object with the accepted file extensions in the format [MIMETYPE]: [file extension]
const getAcceptObject = (fileType: "image" | "video") => {
  const acceptedExtensions =
    fileType === "image"
      ? SUPPORTED_IMAGE_EXTENSIONS
      : SUPPORTED_VIDEO_EXTENSIONS
  return chain(acceptedExtensions)
    .keyBy(extension => `${fileType}/${extension.split(".")[1]}`)
    .mapValues(val => [val])
    .value()
}

const MediaUploadInput = ({
  allowVideoFiles,
  className,
  disabled,
  isEmbedded = false,
  shape = "square",
  ...props
}: MediaUploadInputProps) => {
  const acceptedFileTypes = useMemo(() => {
    return {
      ...getAcceptObject("image"),
      ...(allowVideoFiles ? getAcceptObject("video") : {}),
    }
  }, [allowVideoFiles])

  return (
    <MediaUploadInputContainer
      $disabled={disabled || !!props.value}
      $embedded={isEmbedded}
      $shape={shape}
      $showBorder={!props.value}
    >
      {!disabled && (
        <CenterAligned className="absolute inset-0">
          <Icon size={24} value="add" />
        </CenterAligned>
      )}
      <MediaInput
        accept={acceptedFileTypes}
        className={className}
        disableInputOverlay
        disabled={disabled}
        enableInteractiveContent={false}
        supportedExtensions={[
          ...SUPPORTED_IMAGE_EXTENSIONS,
          ...(allowVideoFiles ? SUPPORTED_VIDEO_EXTENSIONS : []),
        ]}
        variant="overlay"
        {...props}
      />
    </MediaUploadInputContainer>
  )
}

const MediaUploadDescription = ({
  children,
  disableAnimation,
  status,
  textOverrides,
  ...textProps
}: MediaUploadDescriptionProps) => {
  const t = useTranslate("collectionEdit")
  const transition = {
    type: "tween",
    duration: 0.25,
  }
  const transitionWithDelay = {
    ...transition,
    delay: 0.1,
  }
  const enterOpacityTransition = {
    opacity: 1,
    transition,
  }
  const exitOpacityTransition = {
    opacity: 0,
    transition,
  }
  return (
    <AnimatePresence>
      <MediaUploadDescriptionContainer>
        {(status === "complete" || status === "uploading") && (
          <StatusTextContainer>
            {status === "complete" && (
              <motion.div
                animate={{
                  opacity: 1,
                  transition: transitionWithDelay,
                }}
                exit={exitOpacityTransition}
                initial={disableAnimation ? false : { opacity: 0 }}
                key={status}
              >
                <MediaUploadDescriptionText
                  color="success"
                  {...textProps}
                  size="small"
                  weight="semibold"
                >
                  {textOverrides?.complete ??
                    t("dropPageEditor.uploaded", "Uploaded")}
                </MediaUploadDescriptionText>
              </motion.div>
            )}
            {status === "uploading" && (
              <motion.div
                animate={enterOpacityTransition}
                exit={exitOpacityTransition}
                initial={disableAnimation ? false : { opacity: 0 }}
                key={status}
              >
                <MediaUploadDescriptionText
                  color="blue-3"
                  {...textProps}
                  size="small"
                  weight="semibold"
                >
                  {textOverrides?.uploading ??
                    `${t("dropPageEditor.uploading", "Uploading")}...`}
                </MediaUploadDescriptionText>
              </motion.div>
            )}
            <Flex alignItems="center" marginLeft="8px">
              {status === "uploading" && (
                <motion.div
                  animate={enterOpacityTransition}
                  exit={exitOpacityTransition}
                  initial={disableAnimation ? false : { opacity: 0 }}
                  key={`${status}-icon`}
                >
                  <Spinner />
                </motion.div>
              )}
              {status === "complete" && (
                <motion.div
                  animate={{
                    opacity: 1,
                    rotate: 360,
                    scale: 1,
                    transition: transitionWithDelay,
                  }}
                  initial={
                    disableAnimation
                      ? false
                      : { opacity: 0, rotate: 180, scale: 0 }
                  }
                >
                  <Icon className="text-green-2" size={24} value="check" />
                </motion.div>
              )}
            </Flex>
          </StatusTextContainer>
        )}
        {/* This component does not exit the DOM so we can avoid changing the height of the container */}
        <motion.div
          animate={status}
          initial={false}
          key={status}
          variants={{
            idle: { opacity: 1 },
            uploading: exitOpacityTransition,
            complete: exitOpacityTransition,
          }}
        >
          <MediaUploadDescriptionText
            className="text-secondary"
            {...textProps}
            size="tiny"
          >
            {t(
              "dropPageEditor.dragAndDrop",
              "Drag and drop or click to upload",
            )}
          </MediaUploadDescriptionText>
          <MediaUploadDescriptionText
            className="text-secondary"
            {...textProps}
            size="tiny"
          >
            {children}
          </MediaUploadDescriptionText>
        </motion.div>
      </MediaUploadDescriptionContainer>
    </AnimatePresence>
  )
}

const MediaUploadBase = ({
  children,
  className,
  onRemove,
  error,
  ...props
}: {
  children: React.ReactNode
  className?: string
  error?: boolean
  onRemove?: () => unknown
} & CenterAlignedProps) => {
  return (
    <CenterAligned
      className={classNames(
        "relative rounded-xl border border-level-2 p-6",
        inputVariants({ error }),
        className,
      )}
      {...props}
    >
      {children}
      {onRemove ? <RemoveButton onRemove={onRemove} /> : null}
    </CenterAligned>
  )
}

const RemoveButton = ({
  className,
  onRemove,
  size = 16,
}: {
  className?: string
  size?: number
  onRemove: () => unknown
}) => {
  return (
    <Flex
      className={classNames(
        "absolute right-2 top-2 z-[1] sm:right-3 sm:top-3",
        className,
      )}
    >
      <UnstyledButton
        aria-label="Remove"
        onClick={event => {
          event.stopPropagation()
          event.preventDefault()
          onRemove()
        }}
      >
        <RemoveIconStyled size={size} value="close" />
      </UnstyledButton>
    </Flex>
  )
}

const RemoveIconStyled = styled(Icon)`
  color: ${props => props.theme.colors.text.secondary};
  ${interactiveStylesPrimary}
`

const MediaUploadDescriptionContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  position: relative;
`

const StatusTextContainer = styled(Flex)`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const MediaUploadDescriptionText = styled(Text.Body).attrs({
  as: "p",
})`
  display: block;
  text-align: center;
`

const MediaUploadInputContainer = styled(Block)<{
  $disabled?: boolean
  $embedded?: boolean
  $shape?: InputShape
  $showBorder?: boolean
}>`
  position: relative;
  width: 100%;
  max-width: 384px;
  margin-left: auto;
  margin-right: auto;
  height: 0;
  padding-bottom: ${({ $shape }) => {
    switch ($shape) {
      case "landscape":
        return `${(5 / 12) * 100}%`
      case "portrait":
        return `${(5 / 4) * 100}%`
      default:
        return "100%"
    }
  }};
  ${({ $shape }) => {
    return (
      $shape == "fill" &&
      `
    height: 100%;
    padding-bottom: unset;
    `
    )
  }}
  position: relative;
  overflow: hidden;

  ${({ $embedded, $showBorder, $shape, theme }) =>
    !$embedded &&
    css`
      border: 1px dashed
        ${$showBorder ? theme.colors.components.border.level2 : "transparent"};
      border-radius: ${$shape === "circle" ? "50%" : "8px"};
      transition: border-color ease-in-out 0.25s;
    `}

  ${({ $disabled, theme }) =>
    !$disabled &&
    css`
      &:hover {
        ${themeVariant({
          variants: {
            light: {
              borderColor: theme.colors.slate,
            },
            dark: {
              borderColor: theme.colors.gray,
            },
          },
        })}
      }
    `}
`

export const MediaUpload = Object.assign(MediaUploadBase, {
  Description: MediaUploadDescription,
  Input: MediaUploadInput,
  RemoveButton,
})
