import React, { ReactNode, createContext } from "react"
import { useFragment } from "react-relay"
import { CollectionFormProvider_data$key } from "@/lib/graphql/__generated__/CollectionFormProvider_data.graphql"
import { graphql } from "@/lib/graphql/graphql"
import {
  UseCollectionForm,
  useCollectionForm,
} from "../hooks/useCollectionForm"

type ContextType = UseCollectionForm

export const FormCollectionContext = createContext<ContextType>(
  {} as ContextType,
)

export function CollectionFormProvider({
  children,
  dataKey,
  refetch,
}: {
  children: ReactNode
  dataKey: CollectionFormProvider_data$key | null
  refetch: () => unknown
}) {
  const data = useFragment<CollectionFormProvider_data$key>(
    graphql`
      fragment CollectionFormProvider_data on Query {
        ...useCollectionForm_data
      }
    `,
    dataKey,
  )
  const context = useCollectionForm(data, refetch)
  return (
    <FormCollectionContext.Provider value={context}>
      {children}
    </FormCollectionContext.Provider>
  )
}
