import React, { useState } from "react"
import { RadioButtonGroup } from "@opensea/ui-kit"
import styled from "styled-components"
import { OpenSeaLogo } from "@/components/svgs/OpenSeaLogo.react"
import { PencilAndRulerIcon } from "@/components/svgs/PencilAndRulerIcon.react"
import { Button } from "@/design-system/Button"
import { Flex } from "@/design-system/Flex"
import { Modal } from "@/design-system/Modal"
import { useMultiStepFlowContext } from "@/design-system/Modal/MultiStepFlow.react"
import { MultiStepModal } from "@/design-system/Modal/MultiStepModal.react"
import type { ChainIdentifier } from "@/hooks/useChains/types"
import { useIsMintYourOwnEnabled, useIsSSFEnabled } from "@/hooks/useFlag"
import { useRouter } from "@/hooks/useRouter"
import { useTranslate } from "@/hooks/useTranslate"
import {
  trackClickConfirmCreateCollection,
  trackClickCreateCollection,
} from "@/lib/analytics/events/collectionEvents"
import { ContractConfigurationForm } from "./components/ContractConfigurationForm"
import {
  DeployContractActionModal,
  DeployContractActionModalProps,
} from "./components/DeployContractActionModal"

export const CreateCollectionModal = () => {
  const isMintYourOwnNFTEnabled = useIsMintYourOwnEnabled()
  const router = useRouter()
  const isSSFEnabled = useIsSSFEnabled()

  if (isMintYourOwnNFTEnabled) {
    return (
      <CreateCollectionButton onClick={() => router.push(`/studio/create`)} />
    )
  }

  if (isSSFEnabled) {
    return (
      <MultiStepModal
        trigger={open => <CreateCollectionButton onClick={open} />}
      >
        {onClose => {
          return <ModalContent onClose={onClose} />
        }}
      </MultiStepModal>
    )
  }

  return null
}

type CreateCollectionType = "shared_storefront" | "own_contract"

const CreateCollectionButton = ({ onClick }: { onClick: () => unknown }) => {
  const t = useTranslate("collection")
  return (
    <Button
      aria-label={t(
        "collections.header.createCTA.ariaLabel",
        "Create a collection",
      )}
      onClick={() => {
        trackClickCreateCollection()
        onClick()
      }}
    >
      {t("collections.header.createCTA.copy", "Create a collection")}
    </Button>
  )
}

const ModalContent = ({ onClose }: { onClose: () => unknown }) => {
  const t = useTranslate("collection")
  const { onNext } = useMultiStepFlowContext()
  const router = useRouter()
  const [selectedType, setSelectedType] =
    useState<CreateCollectionType>("own_contract")
  const ownContractOption = {
    body: t(
      "createOption.ownContract.body",
      "Drop your collection on OpenSea to let your community mint it.",
    ),
    label: t("createOption.ownContract.label", "Deploy your own contract"),
    icon: (
      <IconBackground>
        <PencilAndRulerIconStyled height={16} width={16} />
      </IconBackground>
    ),
    value: "own_contract",
  }
  const sharedStorefrontOption = {
    body: t(
      "createOption.sharedStorefront.body",
      "Mint items into your collection at any time.",
    ),
    label: t("createOption.sharedStorefront.label", "Use the OpenSea contract"),
    icon: (
      <IconBackground>
        <OpenSeaLogoStyled height={21} width={21} />
      </IconBackground>
    ),
    value: "shared_storefront",
  }
  const options = [ownContractOption, sharedStorefrontOption]

  return (
    <Modal.Form data-testid="contract-configure-form-container">
      <Modal.Header>
        <Modal.Header.Title>
          {t("collection.modal.header", "Create a collection")}
        </Modal.Header.Title>
      </Modal.Header>
      <Modal.Body>
        <RadioButtonGroup
          defaultValue={selectedType}
          name="sell-type"
          onValueChange={value => {
            setSelectedType(value as CreateCollectionType)
          }}
        >
          {options.map(option => (
            <RadioButtonGroup.Item key={option.value} value={option.value}>
              {option.icon}
              <RadioButtonGroup.Item.Content>
                <RadioButtonGroup.Item.Title>
                  {option.label}
                </RadioButtonGroup.Item.Title>
                <RadioButtonGroup.Item.Description>
                  {option.body}
                </RadioButtonGroup.Item.Description>
              </RadioButtonGroup.Item.Content>
            </RadioButtonGroup.Item>
          ))}
        </RadioButtonGroup>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button
          aria-label={t(
            "collection.modal.confirmCTA.ariaLabel",
            "Confirm creation",
          )}
          onClick={() => {
            trackClickConfirmCreateCollection({ collectionType: selectedType })
            if (selectedType === "shared_storefront") {
              const route = "/collection/create"
              router.push(route)
            } else {
              onNext(
                <ContractConfigurationForm
                  onSubmit={({ name, symbol, chain, image, contract }) => {
                    const formValues: Omit<
                      DeployContractActionModalProps,
                      "onClose"
                    > = {
                      image,
                      contract,
                      name,
                      symbol,
                      chain: chain as ChainIdentifier,
                    }
                    onNext(
                      <DeployContractActionModal
                        {...formValues}
                        onClose={onClose}
                      />,
                    )
                  }}
                />,
              )
            }
          }}
        >
          {t("collection.modal.confirmCTA.copy", "Confirm")}
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal.Form>
  )
}

const PencilAndRulerIconStyled = styled(PencilAndRulerIcon)`
  fill: ${props => props.theme.colors.text.primary};
`

const OpenSeaLogoStyled = styled(OpenSeaLogo)`
  fill: ${props => props.theme.colors.text.primary};
`

const IconBackground = styled(Flex)`
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  margin-right: 24px;
  width: 40px;
  background-color: ${props => props.theme.colors.components.border.level2};
`
